<template>
  <div>
    <!-- <v-container fluid class="down-top-padding"> -->
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout v-else wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <v-form ref="form" lazy-validation>
          <v-layout wrap>
            <v-flex xs12 pt-2>
              <v-card>
                <v-card-title class="elevation-1">
                  Story Categories
                  <v-spacer></v-spacer>
                  <!-- <v-col cols="12" sm="3">
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      solo
                      label="Search"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col> -->
                  <v-row justify="end" class="px-5">
                    <v-dialog
                      :retain-focus="false"
                      v-model="dialog"
                      persistent
                      max-width="600px"
                      :key="dialog"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn outlined color="green" v-bind="attrs" v-on="on">
                          Add
                        </v-btn>
                      </template>
                      <v-card>
                        <v-form v-model="addcat" ref="addcat">
                          <v-card-title>
                            <span class="headline">Add Category</span>
                          </v-card-title>
                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col cols="12">
                                  <v-text-field
                                    v-model="name"
                                    label="Category Name"
                                    :rules="[rules.required]"
                                    required
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                  <v-text-field
                                    v-model="caption"
                                    label="Caption"
                                  ></v-text-field>
                                </v-col>
                                <v-layout justify-center wrap>
                                  <v-flex xs12>
                                    <span>Select Color</span>
                                  </v-flex>
                                </v-layout>
                                <v-flex xs12 pl-2>
                                  <v-color-picker
                                    dot-size="25"
                                    hide-mode-switch
                                    mode="hexa"
                                    swatches-max-height="200"
                                    v-model="color"
                                  ></v-color-picker>
                                </v-flex>
                              </v-row>
                            </v-container>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="dialogclose()"
                            >
                              Close
                            </v-btn>
                            <v-btn
                              color="blue darken-1"
                              text
                              :disabled="!addcat"
                              @click="categoryadd()"
                            >
                              Save
                            </v-btn>
                          </v-card-actions>
                        </v-form>
                      </v-card>
                    </v-dialog>
                  </v-row>
                </v-card-title>
                <v-data-table
                  :headers="headers"
                  :items="category"
                  :search="search"
                  hide-default-footer
                  class="elevation-1"
                >
                 <!-- <v-avatar :color="storage.textColor" size="15" /> -->
                 <template v-slot:[`item.color`]="{ item }">
                   <v-avatar :color="item.color" size="15" />
                 </template>
                  <template v-slot:[`item._id`]="{ item }">
                    <v-icon small class="mr-2" @click="editcat(item)">
                      mdi-pencil
                    </v-icon>
                    <v-dialog v-model="item.delete" max-width="600px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          small
                          class="mr-2"
                          v-on="on"
                          v-bind="attrs"
                          color="red"
                        >
                          mdi-delete
                        </v-icon>
                      </template>
                      <v-card>
                        <v-card-title
                          >Are you sure you want to delete this
                          Category?</v-card-title
                        >
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="item.delete = false"
                            >Cancel</v-btn
                          >
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="deleteItem(item)"
                            >OK</v-btn
                          >
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </template>
                  <template v-slot:top>
                    <v-dialog
                      v-model="editdialog"
                      :retain-focus="false"
                      max-width="600px"
                    >
                      <v-card>
                        <v-card-title>
                          <span class="headline">Edit Category </span>
                        </v-card-title>

                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col cols="12">
                                <v-text-field
                                  v-model="editingitem.name"
                                  label="Category Name"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12">
                                <v-text-field
                                  v-model="editingitem.caption"
                                  label="Caption"
                                ></v-text-field>
                              </v-col>
                              <v-layout justify-center wrap>
                                <v-flex xs12>
                                  <span>Select Color</span>
                                </v-flex>
                              </v-layout>
                              <v-flex xs12 pl-2>
                                <v-color-picker
                                  dot-size="25"
                                  hide-mode-switch
                                  mode="hexa"
                                  swatches-max-height="200"
                                  v-model="editingitem.color"
                                ></v-color-picker>
                              </v-flex>
                            </v-row>
                          </v-container>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="editdialog = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn color="blue darken-1" text @click="edit()">
                            Save
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </template>
                </v-data-table>
                <div class="pt-2">
            <v-pagination
              :length="pages"
              :total-visible="7"
              v-model="currentPage"
              color="#FF0000"
            ></v-pagination>
          </div>
              </v-card>
            </v-flex>
          </v-layout>
        </v-form>
      </v-flex>
    </v-layout>
    <!-- </v-container> -->
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      msg: null,
      count: 10,
      currentPage: 1,
      pages: 0,
      totalData: 0,
      totalRows: 0,
      dialog: false,
      editdialog: false,
      editingitem: {},
      name: "",
      caption: "",
      tags: "",
      tagArray: [],
      appLoading: false,
      dialogDelete: false,
      search: "",
      itemname: "",
      addcat: false,
      items: ["meta"],
      title: null,
      color: "",
      headers: [
        { text: "Category Name", value: "name" },
        { text: "Caption", value: "caption" },
        { text: "Color", value: "color" },
        // { text: "Phone", value: "phone" },
        // { text: "Email", value: "email" },
        { text: "Actions", value: "_id", sortable: false },
      ],
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
      },
      category: [],
      data: [],
    };
  },
  watch: {
    currentPage() {
      this.getData();
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    deleteItem(cat) {
      var data = {};
      data["id"] = cat._id;
      axios({
        url: "/story/category/remove",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;

            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/story/category/getlist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params:{
          count: this.count,
          page: this.currentPage,
        }
      })
        .then((response) => {
          this.appLoading = false;
          this.category = response.data.data;
          this.pages = response.data.pages;
          this.totalData = response.data.count;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    dialogclose() {
      this.name = null;
      this.dialog = false;
    },

    categoryadd() {
      var data = {};
      data["name"] = this.name;
      data["caption"] = this.caption;
      data["color"] = this.color;
      axios({
        url: "/story/category/add",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Added Sucessfully";
            this.showsnackbar = true;
            this.name = null;
            this.dialog = false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editcat(item) {
      this.editingitem = item;
      this.editdialog = true;
    },
    edit() {
      var data = {};
      data["id"] = this.editingitem._id;
      data["name"] = this.editingitem.name;
      data["caption"] = this.editingitem.caption;
      data["color"] = this.editingitem.color;
      axios({
        url: "/story/category/edit",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.editdialog = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Edited Sucessfully";
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.table-striped:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>
